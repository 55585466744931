<template lang="pug">
.form.form-order
  .form__block(v-show="!sendForm")
    .form__title(v-html="formTitle")
    .form__fields
      .form__field
        .input-text(:class="{'input-text-focus': (fieldName.length >= 3) && (fieldName.length <= 40), 'input-text-error': fieldError && v$.fieldName.$invalid}")
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )

      .form__field
        .input-text(:class="{'input-text-focus': (fieldCompany.length >= 3) && (fieldCompany.length <= 40), 'input-text-error': fieldError && v$.fieldCompany.$invalid}")
          input.input-text__input(
            type="text"
            v-model="fieldCompany"
            :placeholder="formFieldCompanyPlaceholder"
          )

      .form__field
        .input-text(:class="{'input-text-error': fieldError && v$.fieldEmail.$invalid}")
          input.input-text__input(
            type="email"
            v-model="fieldEmail"
            :placeholder="formFieldEmailPlaceholder"
          )

      .form__field
        .input-text(:class="{'input-text-focus': fieldPhone.length === 18, 'input-text-error': fieldError && v$.fieldPhone.$invalid}")
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )

      .form__field
        .input-text(:class="{'input-text-focus': (fieldProduct.length >= 3) && (fieldProduct.length <= 40), 'input-text-error': fieldError && v$.fieldProduct.$invalid}")
          input.input-text__input(
            type="text"
            v-model="fieldProduct"
            :placeholder="formFieldProductPlaceholder"
          )

      .form__field
        .input-text(:class="{'input-text-focus': (fieldSize.length >= 1) && (fieldSize.length <= 40), 'input-text-error': fieldError && v$.fieldSize.$invalid}")
          input.input-text__input(
            type="text"
            v-model="fieldSize"
            :placeholder="formFieldSizePlaceholder"
          )

      .form__field
        .input-textarea
          textarea.input-textarea__input(
            v-model="fieldText"
            :placeholder="formFieldTextPlaceholder"
          )

    .form__info
      .form__text(v-html="formText")

      .notepad(v-html="formNotepad")


    .form__consent
      label.consent.consent-gray
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form__button
      .button.button-background-yellow(@click="getSend()")
        .button__text(v-html="formButtonName")

  .form__block(v-show="sendForm")
    .form__title(v-html="formSuccessTitle")
    p.center(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, minLength, required, email} from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormOrder',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldCompany",
    "formFieldCompanyPlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldProduct",
    "formFieldProductPlaceholder",
    "formFieldSize",
    "formFieldSizePlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formText",
    "formNotepad",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formRedirect",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldCompany: '',
      fieldEmail: '',
      fieldPhone: '',
      fieldProduct: '',
      fieldSize: '',
      fieldText: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        let curData = new Date()
        let curDay = curData.getDate()
        let curMonth = curData.getMonth() + 1
        let curYear = curData.getFullYear()

        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject,
            formDate: curDay + '.' + curMonth + '.' + curYear
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldCompany,
              value: this.fieldCompany
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldProduct,
              value: this.fieldProduct
            },
            {
              title: this.formFieldSize,
              value: this.fieldSize
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldCompany = this.fieldEmail = this.fieldPhone = this.fieldProduct = this.fieldSize = this.fieldText = ''

        setTimeout(() => {
          window.location.href = this.formRedirect
        }, 2500)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldCompany: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldEmail: {
        required,
        email
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      fieldProduct: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldSize: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(40)
      },

      consent: {
        required
      }
    }
  }
}
</script>
